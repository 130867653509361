/* ---RSUITE--- */

/*SIDENAV*/

/* NAV ITEM */

.rs-subtitle-text {
  color: var(--rs-sidenav-subtitles-color-text, #d0d0d0);
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.text-multi {
  --tw-text-opacity: 1;
  color: var(--rs-color-titles);
}

.rs-nav-icon {
  color: var(--rs-icon-nav-color, #d0d0d0);
}

.rs-btn-icon-with-text.rs-btn-primary > .rs-icon {
  color: var(--rs-iconbtn-primary-addon-color);
  background-color: var(--rs-iconbtn-primary-addon-bg);
}

.rs-btn-icon-with-text.rs-btn-primary:hover > .rs-icon,
.rs-btn-icon-with-text.rs-btn-primary:focus > .rs-icon {
  color: var(--rs-iconbtn-primary-activated-addon-color);
  background-color: var(--rs-iconbtn-primary-activated-addon-bg);
}

.rs-btn-primary {
  color: var(--rs-btn-primary-text);
  background-color: var(--rs-btn-primary-bg);
}

.rs-btn-primary:hover,
.rs-btn-primary:focus {
  color: var(--rs-btn-primary-text-hover);
  background-color: var(--rs-btn-primary-hover-bg);
}

.border-b {
  border-bottom: 0.1px solid black;
}

.bg-primary-header {
  background-color: var(--rs-sidenav-default-dashboard-header-bg, #d0d0d0);
}
.bg-secondary-header {
  background-color: var(--rs-sidenav-default-dashboard-header-bg, #d0d0d0);
}
.bg-secondary-footer {
  background-color: var(--rs-sidenav-default-dashboard-footer-bg, #d0d0d0);
}

.border-any-app {
  border-color: var(--rs-sidenav-default-dashboard-border-color, #d0d0d0);
}

.bg-color-nav {
  background-color: var(--rs-sidenav-default-nav-bg, #d0d0d0);
}

.rs-sidenav-default .rs-sidenav-item {
  background-color: var(--rs-sidenav-default-item-bg, #d0d0d0);
  font-size: var(--rs--sidenav-default-item-font-size, #d0d0d0);
  color: var(--rs--sidenav-default-item-text-color, #d0d0d0);
}

.rs-sidenav-default.rs-sidenav-collapse-in
  .rs-sidenav-item.rs-sidenav-item-active {
  color: var(--rs-sidenav-default-item-active-color, #d0d0d0);
}

/*HOVER*/
/* Color de fondo al pasar el ratón */
.rs-sidenav-default .rs-sidenav-item:hover {
  background-color: var(--rs-sidenav-default-hover-bg, #d0d0d0);
  color: var(--rs-sidenav-default-hover-color-text, #d0d0d0) !important;
}

/* NAV MENU */

.rs-sidenav-collapse-in .rs-dropdown {
  color: var(--rs-sidenav-default-menu-color-text, #d0d0d0);
}

.rs-sidenav-default .rs-sidenav-item.rs-sidenav-item-active,
.rs-sidenav-default .rs-dropdown-item.rs-dropdown-item-active {
  color: var(--rs-sidenav-default-item-active-color, #d0d0d0);
}

.rs-sidenav-default.rs-sidenav-collapse-in
  .rs-dropdown.rs-dropdown-selected-within
  .rs-sidenav-item-icon {
  color: var(--rs-sidenav-default-item-active-color, #d0d0d0);
}

/* TABLA */
.table-header-style-secondary {
  color: #212121 !important;
  font-weight: 550 !important;
  text-transform: uppercase !important;
}

.table-header-style {
  background-color: rgb(33, 33, 33);
  color: #fafafa !important;
}

/*HOVER*/
/* Color de fondo al pasar el ratón */
.rs-dropdown-item.rs-dropdown-item:hover {
  color: var(--rs-sidenav-default-hover-color-text, #d0d0d0) !important;
}

/* TABLAS */
.rs-pagination-btn.rs-pagination-btn-active {
  color: var(--rs-btn-ghost-text, #d0d0d0);
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--rs-picker-value, #d0d0d0);
}

.a {
  color: var(--rs-text-link, #d0d0d0);
}

.a:hover,
a:focus {
  color: var(--rs-text-link-hover, #d0d0d0);
}

.rs-btn-ghost:hover,
.rs-btn-ghost:focus {
  color: var(--rs-btn-ghost-hover-text, #d0d0d0);
  background-color: var(--rs-btn-ghost-hover-bg, #d0d0d0);
  border-color: var(--rs-btn-ghost-hover-border, #d0d0d0);
}

.color-user-avatar {
  background-color: var(--rs-color-user-avatar, #d0d0d0);
}

.color-user-tab {
  background-color: var(--rs-color-user-tab, #d0d0d0);
}

.color-user-text {
  color: var(--rs-color-user-text, #d0d0d0);
}
