@charset "utf-8";
@import "react-tabs/style/react-tabs.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~rsuite/styles/index.less";
@import "theme.less";
@import "react-awesome-lightbox/build/style.css";
@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";
@import "react-tiny-fab/dist/styles.css";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw !important;
  height: 100vh !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: @primary-color transparent;
}

.scrollbar-secondary {
  scrollbar-width: none;
  scrollbar-color: @primary-color-dark transparent;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
  background-color: @primary-color-dark;
  border-radius: 10px;
  border: 0px none #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: @primary-color;
  border-radius: 10px;
  border: 0px none #ffffff;
}

* {
  user-select: none;
  box-sizing: border-box !important;
}

.firebase-emulator-warning {
  display: none;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  height: fit-content !important;
  width: fit-content !important;
}

.rs-breadcrumb {
  margin-bottom: 0 !important;
}

.main-app-header {
  height: @header-height !important;
}

.main-app-header-top-sentinel {
  position: absolute;
  top: calc(@header-height - 15px);
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  z-index: 9999;
}
